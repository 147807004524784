@media (max-width: 768px) {
    .main_container {
        background-image: url("../../images/BG.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        align-items: center;
        justify-content: center;

    }

    .apply_media_query_left_side {
        display: none;
    }

    .apply_media_query_right_side {
        width: 100vw;
        height: auto;
        padding: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        margin: 25px;
        background-color: rgba(255, 255, 255, 0.4);
        /* -webkit-backdrop-filter: blur(5px); */
        backdrop-filter: blur(5px);
    }

    .logoimg {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text {
        text-align: center;
    }

}
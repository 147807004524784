
.mediaQueryForLoggerBox{
    width: 50%;
    height: 505px;
}

.mediaQueryForGraphBox{
    width: 50%;
    height: 505px;
}

/* Adding Media Qerry  */
@media only screen and (max-width: 768px) {
    .addMediaQuery {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .mediaQueryInHead {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 5px;
        text-align: center;
    }

    .mediaqueryincpcb {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .mediaQueryForLoggerBox{
        width: 100%;
        height: 100%;
    }
    
    .mediaQueryForGraphBox{
        width: 100%;
        height: 100%;
    }

}
.box_bg {
  display: flex;
  flex-direction: column;
  
  .value_bar {
    display: flex;
    flex-direction: column;

    p, h3 {
      text-align: center;
      
      span {
        font-size: 14px;
        font-weight: normal;
      }
    }

    p {
      font-size: 16px;
    }
  }

  p, h5 {
    text-align: center;
  }

  p {
    margin: 0;
    margin-bottom: 4px;
    font-size: 12px;
  }
}

.light_green_bg {
  background-color: #4DCF4D;
}

.muted_bg {
  background-color:  #A9A9A9;
}

.warning_bg {
  background-color: #FEC724;
}

.tomato_red_bg {
  background-color: #F34F4F;
}

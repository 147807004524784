@use "./variables.scss" as v;

@mixin scrollbars() {
  // For Google Chrome
  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: v.$darkGray;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-track {
    background: v.$gray;
  }

  // For Internet Explorer
  & {
    scrollbar-face-color: v.$darkGray;
    scrollbar-track-color: v.$gray;
  }
  @media only screen and (max-width: 576px) {
    &::-webkit-scrollbar {
      width: 0px !important;
    }
  }
}

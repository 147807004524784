
$black :rgb(21, 25, 29);
$white : #ffffff;
$lightGray : rgb(240, 240, 240);
$gray : rgb(221, 221, 221);
$darkGray : rgb(138, 138, 138);
$navy: #04093d;
$bodybg : #e8f0ff;
$tomatoRed : rgb(243 79 79);
$green : rgb(77 207 77);
$yellow : #ffc107;
// rgb(165, 217, 249) 
$navHeight : 50px;
$sideBarWidth : 200px;
$sideBarWidthNegitive : -200px;
@keyframes glowingRedBg {
  0% {
    background-color: #ec4545;
    box-shadow: 0 0 5px #ec4545;
  }
  50% {
    background-color: #f16666;
    box-shadow: 0 0 20px #f16666;
  }
  100% {
    background-color: #ec4545;
    box-shadow: 0 0 5px #ec4545;
  }
}
.redBlinkBg {
  animation: glowingRedBg 1300ms infinite;
}


/*  orange Bg Blink  */
@keyframes glowingOrangeBg {
  0% {
    background-color: #f0681a;
    box-shadow: 0 0 5px #f0681a;
  }
  50% {
    background-color: #f0952e;
    box-shadow: 0 0 20px #f0952e;
  }
  100% {
    background-color: #f0681a;
    box-shadow: 0 0 5px #f0681a;
  }
}
.orangeBlinkBg {
  animation: glowingOrangeBg 1300ms infinite;
}




.ticker-container {
  width: 100%;
  background-color: #F34F4F; /* Light red for alert */
  color: #000;
  overflow: hidden;
  white-space: nowrap;
  padding: 10px 0; /* Extra padding for better readability */
  border-radius: 5px;
  margin-bottom: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Adds depth */
}

.ticker-content {
  color: black;
  display: inline-block;
  padding-left: 100%; /* Start from the right */
  animation: slide-left 30s linear infinite; /* Slightly faster animation */
  font-size: 15px;
  line-height: 1.5;
  letter-spacing: 1px;
  word-spacing: 2px;
}

@keyframes slide-left {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}



